/*=================================
    Main Style v1.0.0
===================================*/
// @import '~ngx-toastr/toastr';
@use '@angular/material' as mat;

@import "/node_modules/bootstrap/scss/functions";
@import 'variables';
@import "/node_modules/bootstrap/scss/variables";
@import "/node_modules/bootstrap/scss/mixins";
@import "/node_modules/bootstrap/scss/bootstrap.scss";
// @import "/node_modules/bootstrap-darkmode/scss/darktheme.scss";
@import "base";
@import "form";
@import "block";
@import "mixin";
@import "utilities";
@import "_font-face";
@include mat.checkbox-density(-1);


