.mat-mdc-form-field  {
  font-family: "Ubuntu-Regular", sans-serif;
  width: 100%;
}
.mat-mdc-input-element {
  margin-top: 0.5em !important;
}
.mat-mdc-form-field-infix {
  padding-bottom: 0px !important;
}
.mat-form-field-prefix .mat-icon-button .mat-icon, .mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}
.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  // font-family: var(--mdc-filled-text-field-label-text-font);
  font-size: $form-control-text-size !important;
  font-weight: 400 !important;
  letter-spacing: normal;
}
.mat-mdc-select {
  // font-family: var(--mdc-filled-text-field-label-text-font);
  font-size: $form-control-text-size !important;
  font-weight: 400 !important;
  letter-spacing: normal;
}
.mat-mdc-option .mdc-list-item__primary-text {
  font-size:  $form-control-text-size !important;
}
.mdc-text-field{
  padding: 0px !important;
}
.mat-mdc-form-field-hint-wrapper, .mat-mdc-form-field-error-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0px !important
}
.form-control {
  margin: 0;
  padding: 5px 5px;
  //border-width: 0px 0px 1px 0px;
  color: $form-control-text-color;
  background-color: $form-control-bg-color;
  border-color: $form-control-border-color;
  font-size: $form-control-text-size;
  border: none !important;
  border-bottom: 1px solid $gray-note !important;
  height: 1px;
}
.card-form-content {
  .form-group{
    margin-bottom: 5px;
  }
}
.form-group label,
.form-group-label{
  font-size: $form-control-text-size;
  color: $gray-note;
}
.mdc-text-field--filled .mdc-floating-label {
  font-family: var(--mdc-filled-text-field-label-text-font);
  font-size: $form-control-text-size !important;
  font-weight: 400 !important;
  letter-spacing: normal;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

::-webkit-inner-spin-button,
::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $green !important;
  border-radius: 6px;
}

::ng-deep .mdc-checkbox__native-control .mdc-checkbox--selected{
  border-color: $green !important;
  background-color: $green !important;
}
 .mdc-checkbox .mdc-checkbox__native-control .mdc-checkbox__background
 {
  border-color: $green !important;
  background-color: $green !important;
}
// ::ng-deep .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
//   border-color: $green !important;
//   background-color: $green !important;
// }

// ::ng-deep .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background, .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
//   border-color: $green !important;
//   background-color: $green !important;
// }

/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color:$green;

}
/*TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version.*/
.mat-mdc-radio-button.mat-accent .mat-radio-inner-circle {
  background-color:$green
}
.mat-mdc-select-arrow {
  padding-right: 20px !important;
 
}
// .mdc-text-field--no-label:not(.mdc-text-field--textarea) .mat-mdc-form-field-input-control.mdc-text-field__input, .mat-mdc-text-field-wrapper .mat-mdc-form-field-input-control {
//   height: 24px !important;
// }
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: 38px !important;
  height: 38px !important;
  padding: 8px;
}
input::-ms-reveal {
  display: none;
}
.mat-mdc-option {
  padding-left: 12px !important;
  padding-right: 12px !important;
}
