/* You can add global styles to this file, and also import other style files */
@import "/src/app/scss/styles.scss";


/* You can add global styles to this file, and also import other style files */

@import "~bootstrap-icons/font/bootstrap-icons.css";


/* Importing Bootstrap SCSS file. */
 @import "bootstrap/scss/bootstrap";
//@import "bootstrap/scss/bootstrap";


/* Importing Datepicker SCSS file. */
// @import "~ngx-bootstrap/datepicker/bs-datepicker.scss";
@import "../node_modules/angular-calendar/css/angular-calendar.css";

/* Importing FOnt file. */
// @import "../src/assets/fonts.scss";
