html,
body {
  height: 100%;
  overflow-x: hidden;
  font-family: "Ubuntu-Regular" !important;
}
h1, h2, h3, h4 {
  font-family: "Ubuntu-Bold", sans-serif !important;
}
.top-space {
  margin-top:10px !important;
}

.box-radius {
  border-radius: 5px;
}

.gutter-space {
  height: 2rem;
}

.gutter-space-medium {
  height: 1.5rem;
}

.gutter-space-small {
  height: 1rem;
}

.align-r {
  text-align: right;
}

table {
  width: 100%;
}

.normal-txt {
  font-size: 1rem;
  font-weight: normal;
  font-family: $ubuntu-regular;
}

body::-webkit-scrollbar {
  width: 0.5rem;
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: $gray;
  outline: 2px solid slategrey;
}

.container-fluid {
  height: 100%;
}
.mdc-checkbox {
  --mdc-checkbox-state-layer-size : 16px !important;
  padding: calc((var(--mdc-checkbox-state-layer-size) - 18px) / 2) !important;
  margin: calc((var(--mdc-checkbox-state-layer-size) - var(--mdc-checkbox-state-layer-size)) / 2) !important;
}
// .col-lg-12 {
//   padding-left: 20px !important;
// }
.mdc-data-table__cell, .mdc-data-table__header-cell {
  padding: 0 16px 0 24px !important;
}
.logout-btn {
  color: $red-second;
}
.page-label-header {
  font-size: 1.5rem;
  font-family: 'Ubuntu-Bold', sans-serif;
  width: 20%;
}
.theme-switch {
  background: $white;
  padding: 6px;
  right: 0px;
  top: 61px;
  position: fixed;
  z-index: 1024;

  .btn-secondary {
    display: none;
  }
}

.gutters-5{
  margin-left: -5px;
  margin-right: -5px;
  &>*{
    padding-left: 5px;
    padding-right: 5px;
  }
}
.gutters-8{
  margin-left: -8px;
  margin-right: -8px;
  &>*{
    padding-left: 8px;
    padding-right: 8px;
  }
}
.gutters-10{
  margin-left: -10px;
  margin-right: -10px;
  &>*{
    padding-left: 10px;
    padding-right: 10px;
  }
}

.app-icon {
  &.abuse-icon {
    color: $lightRed
  }
}
input.mat-mdc-input-element::-webkit-calendar-picker-indicator {
  display: block;
}

::ng-deep .mat-icon {
  width: 30px !important;
  overflow: visible !important;
}